<template>
<div>
<section class="bg-light">
  <div class="container-lg" v-if="document">
    <div class="row">
      <div class="row">
        <div class="d-flex col-auto">
          <router-link :to="{ name: 'Home' }" class="btn btn-outline-primary border-0 mb-5"><i class="bi bi-arrow-left"></i> Back to demo templates</router-link>
        </div>

<div class="dropdown col-auto" v-if="userInfo.document.admin || document.createdBy === user.email">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Admin
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <router-link :to="{ name: 'CreateDemoContent', params: { id: props.id}}" class="dropdown-item"><i class="bi bi-pencil"></i> Edit page</router-link>
    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addResource" href="#" @click="updateResourceObj('','add')"><i class="bi bi-card-text"></i> Add resource</a></li>
    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addContent" @click="updateContentObj('','add')"><i class="bi bi-plus"></i> Add demo content</a></li>
    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#manageVisibility" ><i class="bi bi-person-plus-fill"></i> Share</a></li>
    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#updateGroups" ><i class="bi bi-people-fill"></i> Manage Groups</a></li>
    <li v-if="document.visibility == 'private'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteDemo" ><i class="bi bi-trash"></i> Delete</a></li>
  </ul>
</div>


  
        <!-- <button type="button" class="btn btn-outline-secondary border-0 mb-5" data-bs-toggle="modal" data-bs-target="#addResource" v-if="userInfo.document.admin">
          <i class="bi bi-plus"></i> Add resources
        </button>
        <button type="button" class="btn btn-outline-secondary border-0 mb-5" data-bs-toggle="modal" data-bs-target="#addContent" @click="updateContentObj('','add')" v-if="userInfo.document.admin">
          <i class="bi bi-plus"></i> Add demo content
        </button> -->

        </div>
      <div class="col-md-7">
        <h1 class="display-4">{{document.title[userInfo.document.language] || document.title['en']}}</h1>
        <p style="white-space: pre-line;">{{document.description[userInfo.document.language] || document.description['en']}}</p>
        <div class="row row-cols-auto">
          <div class="col" v-for="app in document.apps" :key="app">
            <img :src="getImgUrl(app)" :alt="app" width="30">
          </div>
          
        </div>
        </div>
        <div class="col-md-5">
        <div v-for="form in forms" :key="form.title">
          
          <h6>{{ form.title }} 
            <div v-for="tag in form.fields" :key="tag.id"><span class="badge" id="badge" v-if="document.tags.includes(tag.value)">{{ tag.name }}</span></div>
          </h6>
        
        </div>
        <div v-if="document.demoTime">
          <h6>{{translated.demoTime}}</h6>
          <span class="badge" id="badge">{{ document.demoTime }} {{translated.minutes}}</span>
        </div>
        
        </div>
    </div>
        <div v-if="!document.hideCreate">
          <router-link :to="{ name: 'CreateDemo', params: { id: props.id}}" class="btn btn-primary btn-lg mt-4">{{ translated.button.create}} <i class="bi bi-arrow-right"></i></router-link>
        </div>
        <div v-if="document.title['en']=='Learn LLMs with Gemini'">
          
          <div v-if="userInfo.document.duetAI && timeDifferenceInMinutes(userInfo.document.duetAITime) >= 0">
          <button class="btn btn-success btn-lg mt-4">Gemini for Workspace available for <CountdownTimer :startingMinutes="timeDifferenceInMinutes(userInfo.document.duetAITime)" /></button>
        </div>
          <div v-else>
            <!-- <button class="btn btn-primary btn-lg mt-4" @click="enableDuetAI" :disabled="userInfo.document.duetAI==true">Enable Duet AI</button> -->
            <router-link :to="{ name: 'CreateDemo', params: { id: props.id}}" class="btn btn-primary btn-lg mt-4">{{ translated.button.create}} <i class="bi bi-arrow-right"></i></router-link>
          </div>
        </div>
        

        </div>
</section>
<section v-if="resources.length!=0">
  <div class="container-lg mt-3">
      <h2 class="display-6">{{ translated.subTitle }}</h2> 
      <!-- <accordion :accordionItems="accordionItems" /> -->

      <div class="accordion" id="accordion">
  <div class="accordion-item" v-for="(item, index) in accordionItems" :key="item.id">
    <h2 class="accordion-header" :id="item.id">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne'+item.id" aria-expanded="false" :aria-controls="'flush-collapseOne'+item.id">
        {{ item.title[userInfo.document.language] || item.title['en'] }}
      </button>
    </h2>
    <div :id="'flush-collapseOne'+item.id" class="accordion-collapse collapse show" :aria-labelledby="item.id" data-bs-parent="#accordion" v-if="index == 0">
      <div class="accordion-body">
          <div v-dompurify-html="item.content[userInfo.document.language] || item.content['en']"></div>
          <div v-if="userInfo.document.admin || document.createdBy === user.email">
          <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addResource" :data-bs-whatever="item.id" @click="updateResourceObj(item.id,'addLanguage')">
          <i class="bi bi-translate"></i>
        </button>
        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addResource" @click="updateResourceObj(item.id,'edit','en')">
          <i class="bi bi-pencil"></i>
        </button>
        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#deleteResource" @click="updateResourceObj(item.id,'delete')">
          <i class="bi bi-trash"></i>
        </button>
        </div>
      </div>
    </div>
    <div :id="'flush-collapseOne'+item.id" class="accordion-collapse collapse" :aria-labelledby="item.id" data-bs-parent="#accordion" v-if="index != 0">
      <div class="accordion-body">
          <div v-dompurify-html="item.content[userInfo.document.language] || item.content['en']"></div>
        <div v-if="userInfo.document.admin || document.createdBy === user.email">
          <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addResource" :data-bs-whatever="item.id" @click="updateResourceObj(item.id,'addLanguage')">
          <i class="bi bi-translate"></i>
        </button>
        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addResource" @click="updateResourceObj(item.id,'edit','en')">
          <i class="bi bi-pencil"></i>
        </button>
        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#deleteResource" @click="updateResourceObj(item.id,'delete')">
          <i class="bi bi-trash"></i>
        </button>
        </div>
        
      </div>
    </div>
  </div>
</div>



              <div class="col-12">
        <div class="row my-5 align-items-center justify-content-left">
  <div class="d-flex col-auto" v-for="video in videos" :key="video.id">
      <div class="mb-4 " >
            <div class="card-body text-center py-4 d-flex flex-column card border-1 mb-4 shadow-sm">
      
            <div class="embed-responsive embed-responsive-16by9">
  <iframe class="embed-responsive-item" :src="video.url" allowfullscreen></iframe>
</div>
<p class="card-title">{{ video.title }}</p>
            </div>
      </div>
  </div>
 </div>
    </div>
    
    </div>
</section>
<section >
  <div class="container-lg">
     <div class="row" v-if="files.length!=0">
          <h2 class="display-6">Demo content</h2>
        <!-- Button trigger modal -->
        
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th class="col">Shared</th>
              <th scope="col">Languages</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td></td>
              <td>
                  <img :src="getImgUrl(file.fileType)" :alt="file.fileType" width="30"> {{ file.languageFileName[userInfo.document.language] || file.languageFileName['en'] }}
                  </td>
                  <td>
                    <i class="bi bi-check-square" v-if="file.shared"></i>
                  </td>
              <td><span class="badge bg-secondary me-1" v-for="(value, key) in file.languageFileName" :key="key">{{ key }}</span> 
              </td>    
              <td><!-- Button trigger modal -->
              <div v-if="userInfo.document.admin || document.createdBy === user.email">
                <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addLanguageContent" :data-bs-whatever="file.id" :data-bs-type="file.fileType" @click="updateContentObj(file.id,'addLanguage')">
          <i class="bi bi-translate"></i>
        </button>
        
        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#addContent" @click="updateContentObj(file.id,'edit','en')">
          <i class="bi bi-pencil"></i>
        </button>

        <button type="button" class="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#deleteContent" @click="updateContentObj(file.id,'delete')">
          <i class="bi bi-trash"></i>
        </button>
              </div>
        
        
        </td>
            </tr>
            
           
          </tbody>
          
          </table>
      </div>
        </div>
          
         
</section>
<!-- Add/Edit Content Modal -->
<div class="modal fade" id="addContent" tabindex="-1" aria-labelledby="addContentLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addContentLabel">Add/Edit content</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="processForm">
        <div class="modal-body">
          <div v-if="content.id">
            <label for="language" class="form-label">Language:</label>
          <div class="input-group mb-4">
            <span class="input-group-text">
              <i class="bi bi-translate text-secondary"></i>
            </span>
              <select class="form-select" aria-label="Language" id="language" v-model="content.fileLanguage" @click="updateContentObj(content.id,'edit',content.fileLanguage)" required>
                <option v-for="lang in content.languages" :value="lang.code" :key="lang.code">{{ lang.locale }} <span v-if="lang.locale != lang.localeInLanguage">- {{ lang.localeInLanguage }}</span></option>
              </select>
          </div>
          </div>
        
            <label for="fileType" class="form-label">Type:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="fileType" v-model="content.fileType">
                <option selected></option>
                <option value="classroom">Classroom</option>
                <option value="docs">Docs</option>
                <option value="forms">Forms</option>                
                <option value="gmail">Gmail</option>
                <option value="sheets">Sheets</option>
                <option value="slides">Slides</option>
                <option value="folder">Folder</option>
                <option value="action">Action</option>
              </select>
          </div>
          <div v-if="content.fileType == 'classroom'">
            <label for="fileId" class="form-label">Classroom:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="fileType" v-model="content.fileId">
                <option v-for="classroom in classrooms" :key="classroom.id" :value="classroom.id">{{ classroom.name }}</option>
              </select>
          </div>
          </div>
          <div v-else-if="content.fileType == 'action'">
            <label for="fileId" class="form-label">Action:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="fileType" v-model="content.actionType">
                <option value="duetAI">Gemini license</option>
              </select>
          </div>
          </div>
          <div v-else-if="content.fileType == 'gmail'">
            <label for="emailSender" class="form-label">Sender:</label>
          <div class="input-group mb-3">
            <select class="form-select" aria-label="Sender" id="emailSender" v-model="content.emailSender">
                <option value="demo@demogfe.com">demo@demogfe.com</option>
                <option value="noreply@demogfe.com">noreply@demogfe.com</option>
                <option :value="email">{{ email }}</option>
              </select>
              </div>
              <label for="emailSubject" class="form-label">Subject:</label>
          <div class="input-group mb-3">
              <input type="text" id="emailSubject" class="form-control" v-model="content.languageFileName" required />
          </div>
          <label for="emailContents" class="form-label">Message:</label>
          <div class="input-group mb-3">
              <!--<textarea id="emailContents" name="emailContents" v-model="content.emailContents" ></textarea>-->
              <editor
       api-key="1l3bz071b1lzgb94azn2xhqkb7qstm737kxkrnkf58x1s2nw"
       :init="{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace fullscreen',
           'insertdatetime media table paste help'
         ],
         toolbar:
           'undo redo | formatselect | bold italic underline backcolor | \
           bullist numlist outdent indent | link | removeformat | help',
           //toolbar: 'formatselect bold italic underline copy cut paste bullist numlist link'
       }"
       v-model="content.emailContents"
     />
          </div>
          </div>
          <div v-else>
            <label for="fileId" class="form-label">File ID:</label>
          <div class="input-group mb-3">
              <input type="text" id="fileId" class="form-control" v-model="content.fileId" required />
          </div>
          </div>
          <!-- <label for="language" class="form-label">Language:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Language" id="language" v-model="content.fileLanguage">
                <option value="en">English</option>
                <option v-for="lang in languages" :value="lang.code" :key="lang.code">{{ lang.locale }} | {{ lang.localeInLanguage.replaceAll('"','') }}</option>
              </select>
          </div> -->
          <div v-if="content.fileType != 'action' && content.fileType != 'gmail'">
          <label for="languageFileName" class="form-label">Filename (English):</label>
          <div class="input-group mb-3">
              <input type="text" id="fileName" class="form-control" v-model="content.languageFileName" required />
          </div>
          </div>
          <div class="mb-4 form-check" v-if="content.fileType != 'action' && content.fileType != 'gmail'">
              <input type="checkbox" id="shared" class="form-check-input" v-model="content.shared">
              <label for="agreement" class="form-check-label">Shared to delegates</label>              
            </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
        
      </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Add Language Content Modal -->
<div class="modal fade" id="addLanguageContent" tabindex="-1" aria-labelledby="addLanguageContentLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="addLanguageContentLabel">Add language content</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="updateContent">
        
      <div class="modal-body">
          <!-- <label for="contentId" class="form-label">ID:</label>
          <div class="input-group mb-3">
              <input type="text" class="form-control" id="contentId" :value="content.contentId" >
          </div> -->
          <div v-if="content.fileType == 'classroom'">
            <label for="fileId" class="form-label">Classroom:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="fileType" v-model="content.fileId">
                <option v-for="classroom in classrooms" :key="classroom.id" :value="classroom.id">{{ classroom.name }}</option>
              </select>
          </div>
          </div>
          <div v-else>
            <label for="fileId" class="form-label">File ID:</label>
          <div class="input-group mb-3">
              <input type="text" id="fileId" class="form-control" v-model="content.fileId" required />
          </div>
          </div>
          <label for="language" class="form-label">Language:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Language" id="language" v-model="content.fileLanguage">
                <option v-for="lang in languages" :value="lang.code" :key="lang.code">{{ lang.locale }} | {{ lang.localeInLanguage.replaceAll('"','') }}</option>
              </select>
          </div>
          <label for="languageFileName" class="form-label">Language Filename:</label>
          <div class="input-group mb-3">
              <input type="text" id="fileName" class="form-control" v-model="content.languageFileName" required />
          </div>
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
        
      </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete Content Modal -->
<div class="modal fade" id="deleteContent" tabindex="-1" aria-labelledby="deleteContent" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="addLanguageContentLabel">Delete content</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
        
      <div class="modal-body">
          <p>Are you sure you wish to delete {{ content.languageFileName}}?</p>
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" @click="handleDelete(content.id)">Delete</button>
        
      </div>
      
    </div>
  </div>
</div>

<!-- Delete Demo Modal -->
<div class="modal fade" id="deleteDemo" tabindex="-1" aria-labelledby="deleteDemo" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="deleteDemoLabel">Delete demo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
        
      <div class="modal-body">
        <div class="alert alert-warning" role="alert">
         Deleting a demo is permanent and cannot be undone. 
        </div>
        <div class="alert alert-light" role="alert">
          <p>Are you sure you wish to delete {{document.title[userInfo.document.language] || document.title['en']}}?</p>
</div>
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" @click="handleDemoDelete(document.id)">Delete</button>
        
      </div>
      
    </div>
  </div>
</div>

<!-- Add resource modal -->
<div class="modal fade" id="addResource" tabindex="-1" aria-labelledby="addResource" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="addResourceContentLabel">Add resource</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
            <form @submit.prevent="processResForm">

        
      <div class="modal-body">
          <label for="title" class="form-label">Title:</label>
      <div class="input-group mb-4">
              <input type="text" id="title" class="form-control" v-model="demoResource.title" required />
      </div>
      <div v-if="!demoResource.id">
<label for="type" class="form-label">Type:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="type" v-model="demoResource.type">
                <option value="text">Text</option>
                <option value="video">Video</option>
              </select>
          </div>
      </div>
      
          <label for="language" class="form-label">Language:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Language" id="language" v-model="demoResource.language">
                <option v-for="lang in languages" :value="lang.code" :key="lang.code">{{ lang.locale }} | {{ lang.localeInLanguage.replaceAll('"','') }}</option>
              </select>
          </div>
          <div v-if="demoResource.type=='video'">
            <label for="url" class="form-label">YouTube embed url:</label>
      <div class="input-group mb-4">
              <input type="url" id="url" class="form-control" v-model="demoResource.url" required />
      </div>
          </div>
      <div v-if="demoResource.type=='text'">
<label for="content" class="form-label">Content:</label>
      <div class="input-group mb-4">
      <editor
       api-key="1l3bz071b1lzgb94azn2xhqkb7qstm737kxkrnkf58x1s2nw"
       :init="{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help'
         ],
         toolbar:
           'undo redo | formatselect | bold italic underline backcolor | \
           bullist numlist outdent indent | link | removeformat | media | code | help',
           //toolbar: 'formatselect bold italic underline copy cut paste bullist numlist link'
       }"
       v-model="demoResource.content"
     />
      </div>
      </div>
      
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" @click="addResource(content.id)">Save</button>
        
      </div>
            </form>
    </div>
  </div>
</div>

<!-- Manage Demo Visibility Modal -->
<div class="modal fade" id="manageVisibility" tabindex="-1" aria-labelledby="manageVisibilityLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="manageVisibilityLabel">Manage Demo Visibility</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="updateVisibility">
        <div class="modal-body">
          <!-- Visibility Setting -->
          <label for="visibility" class="form-label">Visibility:</label>
          <div class="input-group mb-3">
              <select class="form-select" id="visibility" v-model="document.visibility" required>
                <option value="private">Private</option>
                <option value="shared">Shared</option>
                <option value="public" v-if="userInfo.document.admin">Public</option>
              </select>
          </div>
          <!-- Shared With (if visibility is set to 'shared') -->
          <div v-if="document.visibility === 'shared'">
            
            <label for="sharedWith" class="form-label">Shared With:</label>
            <div class="input-group mb-3">
              <input type="text" id="sharedWith" class="form-control" v-model="sharedWith" placeholder="Enter user IDs, separated by commas" />
            </div>
            <div>
              <span
                class="badge bg-secondary"
                v-for="sharedWithEmail in document.sharedWith"
                :value="sharedWithEmail"
                :key="sharedWithEmail"
                style="margin: 5px;"
              >
                {{ sharedWithEmail }}
                <i class="bi bi-x" @click="deleteEmail(sharedWithEmail)"></i>
              </span>
            </div>
            <div>
              <div>
  <label for="sharedWithDomain" class="form-label">Shared With Domain:</label>
  <div class="input-group mb-3">
    <input type="text" id="sharedWithDomain" class="form-control" v-model="sharedWithDomain" placeholder="Enter domains, separated by commas" />
  </div>
</div>
            <div>
              <span
                class="badge bg-secondary"
                v-for="sharedWithDomain in document.sharedWithDomain"
                :value="sharedWithDomain"
                :key="sharedWithDomain"
                style="margin: 5px;"
              >
                {{ sharedWithDomain }}
                <i class="bi bi-x" @click="deleteDomain(sharedWithDomain)"></i>
              </span>
            </div>
          </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>



<!-- Add to groups modal -->
<div class="modal fade" id="updateGroups" tabindex="-1" aria-labelledby="groupsLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="groupsLabel">Manage User Groups</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="updateGroups">
        <div class="modal-body">
          
          <!-- Shared With (if visibility is set to 'shared') -->
          <div>
            <p>Enter the email addresses of groups to add the demo users to on creation.</p>
            <label for="groups" class="form-label">Groups:</label>
            <div class="input-group mb-3">
              <input type="text" id="groups" class="form-control" v-model="groups" placeholder="Enter group emails, separated by commas" />
            </div>
            <div>
              <span
                class="badge bg-secondary"
                v-for="group in document.groups"
                :value="group"
                :key="group"
                style="margin: 5px;"
              >
                {{ group }}
                <i class="bi bi-x" @click="deleteGroup(group)"></i>
              </span>
            </div>
            
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>




</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { computed, ref } from '@vue/reactivity'
import axios from "axios";
import getCollection from '@/composables/getCollection'
import getSubCollection from '@/composables/getSubCollection'
import getUser from '@/composables/getUser'
import getDoc from '@/composables/getDoc'
// firebase imports
import { db } from '@/firebase/config'
import { doc, addDoc, deleteDoc, updateDoc, collection, serverTimestamp } from 'firebase/firestore'
// import DemoContentCards from '../../components/DemoContentCards.vue'
//import Accordion from '../../components/Accordion.vue'
import Editor from '@tinymce/tinymce-vue'
import translate from '@/composables/translate'
import { useStore } from 'vuex'
import CountdownTimer from '@/components/CountdownTimer.vue';
export default {
  props: ['id'],
  setup(props){
    const router = useRouter()
    const { user,userInfo } = getUser()
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { getLanguage, translated, forms } = translate()
    getLanguage('demoDetails',lang)
    // const { documents: content } = getCollection('content')
    // const { error, document, load } = getDocument('demos',props.id)
    // load()
    const { document } = getDoc('demos',props.id)
    const email = user.value.email
    const userId = user.value.uid

    const tags = computed(() => {
      return fields.value.filter(f => document.value.tags.includes(f.name))
    })

    const languages = ref([])
    axios.get('/data/locale.json')
    .then((res) => {
      languages.value = res.data.languages
    })

    const { documents: files } = getSubCollection('demos',props.id,'content','','languageFileName')
    const { documents: actions } = getSubCollection('demos',props.id,'content',['fileType','==','action'],'')
    const { documents: resources } = getSubCollection('demos',props.id,'resources','','order')
    const videos = computed(() => {
      return resources.value.filter(r => r.type.includes('video'))
    })
    const accordionItems = computed(() => {
      return resources.value.filter(i => i.type.includes('text'))
    })

    function getImgUrl(icon) {
    return require('@/assets/icons/'+icon+'.png')
    }

    const handleDelete = (file) => {
      const docRef = doc(db, 'demos', props.id, 'content', file)

      deleteDoc(docRef)
    }

    const handleDemoDelete = (file) => {
      const docRef = doc(db, 'demos', props.id)

      deleteDoc(docRef)
      router.push({ name: 'Home'})
    }

    const modalTitle = ref(null)
    
    const content = ref({
      fileName: '',
      fileId: '',
      fileType: '',
      fileLanguage: '',
      actionType: '',
      languageFileName: '',
      emailSender: 'demo@demogfe.com',
        emailSubject: '',
        emailContents: ''
    })

    const classrooms = ref()
    function classList() {axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/listClassrooms?username='+user.value.email)
    .then((res) => {
      classrooms.value = res.data
    })}

    console.log('userInfo',email)

    const timeDifferenceInMinutes = (duetAITime) => {
    // Assuming duetAITime is a Firestore Timestamp
    // Convert it to a JavaScript Date object
    const duetAIDate = duetAITime.toDate();

    // Add 4 hours to the duetAITime
    duetAIDate.setHours(duetAIDate.getHours() + 8);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = duetAIDate - now;

    // Convert difference to minutes (Note: 1 minute = 60,000 milliseconds)
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    return diffInMinutes;
  };




    async function enableDuetAI() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/enableDuetAI?email='+user.value.email+'&userDoc='+userId)
            const colRef = collection(db, 'trainings')
        await addDoc(colRef, {
          demoId: props.id,
          demoName: document.value.title['en'],
          sessionName: `${user.value.email} Duet AI`,
          type: 'online',
          startDate: serverTimestamp(),
          endDate: serverTimestamp(),
          createdAt: serverTimestamp(),
          createdBy: user.value.email,
          createdByUid: user.value.uid,
          createdByOrg: userInfo.value.document.userDomain,
          status: ['active','Classroom created'],
          userLicense: '1010470001'
      })
    }

    const updateContentObj = (id,update,language) => {
      if(update === 'edit'){
        
      const file = files.value.filter(f => f.id === id)
      console.log('file obj',file[0])
      const fileLanguages = languages.value.filter(l => Object.keys(file[0].fileId).includes(l.code))
      console.log('fileLanguages',fileLanguages)
      content.value = {
        fileName: file[0].fileName,
        fileId: file[0].fileId[language],
        fileType: file[0].fileType,
        fileLanguage: language,
        languageFileName: file[0].languageFileName[language],
        id: id,
        shared: file[0].shared,
        languages: fileLanguages,
        emailContents: (file[0].emailContents && file[0].emailContents[language]) || '', // Set a default value if not found
        emailSender: file[0].emailSender
      }
      classList()
      }
      else if(update==='add') {
        console.log('clear obj')
        content.value = {
          shared: false
        }
        classList()
    }
    else if (update==='addLanguage') {
      const file = files.value.filter(f => f.id === id)
      content.value ={
        fileType: file[0].fileType,
        id: id,
        shared: file[0].shared
      }
    }
    else if (update==='delete') {
      const file = files.value.filter(f => f.id === id)
      console.log('file',file)
      content.value ={
        languageFileName: file[0].languageFileName['en'],
        id: id
      }
      console.log('update content obj del',content.value)
    }
    
    }
    
    // launch function to process the updateContent or addContent function
    const processForm = () => {
      if (content.value.id) {
        updateContent()
      }
      else {
        addContent()
      }
    }

    const processResForm = () => {
      if (demoResource.value.id) {
        updateResource()
      }
      else {
        addResource()
      }
    }

    const updateResourceObj = (id,update,language) => {
      if(update === 'edit'){
      const resource = resources.value.filter(r => r.id === id)
      console.log(resource)
      const resourceLanguages = languages.value.filter(l => Object.keys(resource[0].title).includes(l.code))
      demoResource.value = {
        id: id,
        title: resource[0].title[language],
        type: resource[0].type,
        content: resource[0].content[language] || '',
        language: language,
        resourceLanguages: resourceLanguages
      }
      }
      else if(update==='add') {
        console.log('clear obj')
        demoResource.value = {
          url: ''
        }
    }
    else if(update==='addLanguage') {
      console.log('add language to resource')
      demoResource.value = {
        id:id,
        type: 'text',
        content: ''
      }
    }
    
    }

    
    
    

    const sharedWith = ref('');
    const sharedWithDomain = ref('');
    const groups = ref('');

    const isValidDomain = computed(() => {
  // Basic domain format validation regex
  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
  return domainRegex.test(sharedWithDomain.value);
    });

const updateVisibility = async () => {

const docRef = doc(db, 'demos', props.id);

let updateData = {
  visibility: document.value.visibility,
  // sharedWithOrg: document.value.sharedWithOrg,
  modifiedAt: serverTimestamp(),
  lastModifiedBy: user.value.email
};
console.log(sharedWith.value)
if (sharedWith.value) {
  let newSharedWith = sharedWith.value.split(',');

  // Trim whitespace from each email and filter out any empty strings
  newSharedWith = newSharedWith.map(email => email.trim()).filter(Boolean);

  // Append "@demogfe.com" to user IDs that don't already include it
  newSharedWith = newSharedWith.map(email => email.includes('@demogfe.com') ? email : email + '@demogfe.com');

  // Merging the new and existing sharedWith arrays
  const mergedSharedWith = [...new Set([...(document.value.sharedWith || []), ...newSharedWith])];

  updateData.sharedWith = mergedSharedWith;
  
}
console.log(sharedWithDomain.value)
if (sharedWithDomain.value) {
  if (!isValidDomain.value) {
    alert('Invalid domain format. Please enter a valid domain.');
    return;
  }
  let newSharedWithDomain = sharedWithDomain.value.split(',');

  // Trim whitespace from each domain and filter out any empty strings
  newSharedWithDomain = newSharedWithDomain.map(domain => domain.trim()).filter(Boolean);

  // Merging the new and existing sharedWithDomain arrays
  const mergedSharedWithDomain = [...new Set([...(document.value.sharedWithDomain || []), ...newSharedWithDomain])];

  updateData.sharedWithDomain = mergedSharedWithDomain;
  console.log(newSharedWithDomain, mergedSharedWithDomain, updateData);
}


const res = await updateDoc(docRef, updateData).then(
  () => {
    // Reset the fields if needed
    sharedWith.value = '';
    sharedWithDomain.value = '';
  }
).catch(error => {
  console.error("Error updating document: ", error);
});
};

const updateGroups = async () => {

const docRef = doc(db, 'demos', props.id);

let updateData = {
  visibility: document.value.visibility,
  // sharedWithOrg: document.value.sharedWithOrg,
  modifiedAt: serverTimestamp(),
  lastModifiedBy: user.value.email
};
console.log(groups.value)
if (groups.value) {
  let newGroups = groups.value.split(',');

  // Trim whitespace from each email and filter out any empty strings
  newGroups = newGroups.map(email => email.trim()).filter(Boolean);

  // Append "@demogfe.com" to user IDs that don't already include it
  newGroups = newGroups.map(email => email.includes('@demogfe.com') ? email : email + '@demogfe.com');

  // Merging the new and existing sharedWith arrays
  const mergedGroups = [...new Set([...(document.value.groups || []), ...newGroups])];

  updateData.groups = mergedGroups;
  
}

const res = await updateDoc(docRef, updateData).then(
  () => {
    // Reset the fields if needed
    groups.value = '';  }
).catch(error => {
  console.error("Error updating document: ", error);
});
};

const deleteGroup = async (groupToDelete) => {
      document.value.groups = document.value.groups.filter(email => email !== groupToDelete);

      try {
        const docRef = doc(db, 'demos', props.id);
        await updateDoc(docRef, {
          groups: document.value.groups
        });
        console.log('Document successfully updated!');
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    };




const deleteEmail = async (emailToDelete) => {
      document.value.sharedWith = document.value.sharedWith.filter(email => email !== emailToDelete);

      try {
        const docRef = doc(db, 'demos', props.id);
        await updateDoc(docRef, {
          sharedWith: document.value.sharedWith
        });
        console.log('Document successfully updated!');
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    };

    const deleteDomain = async (domainToDelete) => {
      document.value.sharedWithDomain = document.value.sharedWithDomain.filter(email => email !== domainToDelete);

      try {
        const docRef = doc(db, 'demos', props.id);
        await updateDoc(docRef, {
          sharedWithDomain: document.value.sharedWithDomain
        });
        console.log('Document successfully updated!');
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    };


    const addContent = async () => {
        if(content.value.actionType && content.value.actionType == 'duetAI') {
          content.value.languageFileName = 'DuetAI'
        }
        const colRef = collection(db, 'demos', props.id, 'content')
        const res = await addDoc(colRef, {
          languageFileName: {
          //[content.value.fileLanguage]: content.value.languageFileName
          ['en']: content.value.languageFileName || ''
        },
        fileType: content.value.fileType,
        fileId: {
          ['en']: content.value.fileId || ''
        },
        actionType: content.value.actionType || '',
        emailSender: content.value.emailSender || '',
        emailContents: {
          ['en']: content.value.emailContents || ''
        },
        demo: props.id,
        shared: content.value.shared,
        createdAt: serverTimestamp(),
        ownerEmail: user.value.email,
        ownerUid: user.value.uid,}).then(
        content.value = {}
      ) 
    }

    const updateContent = async () => {
        const docRef = doc(db, 'demos', props.id, 'content', content.value.id)
        const res = await updateDoc(docRef, {
        [`languageFileName.${content.value.fileLanguage}`]: content.value.languageFileName,
        [`fileId.${content.value.fileLanguage}`]: content.value.fileId,
        fileType: content.value.fileType,
        actionType: content.value.actionType || '',
        [`emailContents.${content.value.fileLanguage}`]: content.value.emailContents || '',
        emailSender: content.value.emailSender || '',
        shared: content.value.shared,
        modifiedAt: serverTimestamp(),
        lastModifiedBy: user.value.email
        }).then(
        content.value = {}
      )}


    const demoResource = ref({
      url: {
          ['en']: ''
      }
    })
    //     Fix language adding later
    //     const addResource = async () => {
    //     const colRef = collection(db, 'demos', props.id, 'resources')
    //     const res = await addDoc(colRef, {
    //       title: {
    //       [demoResource.value.language]: demoResource.value.title
          
    //     },
    //     type: demoResource.value.type,
    //     url: {
    //       [demoResource.value.language]: demoResource.value.url || ''
    //     },
    //     content: {
    //       [demoResource.value.language]: demoResource.value.content || ''
    //     },
    //     demo: props.id,
    //     createdAt: serverTimestamp(),
    //     ownerEmail: user.value.email,
    //     order: '0',
    //     ownerUid: user.value.uid,}).then(
    //     demoResource.value = {}
    //   ) 
    // }

    const addResource = async () => {
        const colRef = collection(db, 'demos', props.id, 'resources')
        const res = await addDoc(colRef, {
          title: demoResource.value.title,
        type: demoResource.value.type,
        url: demoResource.value.url || '',
        content: demoResource.value.content || '',
        demo: props.id,
        createdAt: serverTimestamp(),
        ownerEmail: user.value.email,
        order: '0',
        ownerUid: user.value.uid,}).then(
        demoResource.value = {}
      ) 
    }


    // const updateResource = async () => {
    //     const docRef = doc(db, 'demos', props.id, 'resources', demoResource.value.id)
    //     let resObj
    //     if (demoResource.value.type=='text'){
    //       resObj = {
    //       [`title.${demoResource.value.language}`]: demoResource.value.title,
    //      [`content.${demoResource.value.language}`]: demoResource.value.content,
    //     modifiedAt: serverTimestamp(),
    //     lastModifiedBy: user.value.email
    //     }
    //     }
        
    //     if (demoResource.value.type=='video'){
    //       resObj = {
    //       title: {
    //       [demoResource.value.language]: demoResource.value.title
          
    //     },
    //     type: demoResource.value.type,
    //     url: {
    //       [demoResource.value.language]: demoResource.value.url
    //     },
    //     modifiedAt: serverTimestamp(),
    //     lastModifiedBy: user.value.email
    //     }
    //     }
        
    //     const res = await updateDoc(docRef, resObj).then(
    //     content.value = {}
    //   )}

    const updateResource = async () => {
        const docRef = doc(db, 'demos', props.id, 'resources', demoResource.value.id)
        let resObj
        if (demoResource.value.type=='text'){
          resObj = {
          [`title.${demoResource.value.language}`]: demoResource.value.title,
         [`content.${demoResource.value.language}`]: demoResource.value.content,
        modifiedAt: serverTimestamp(),
        lastModifiedBy: user.value.email
        }
        }
        
        if (demoResource.value.type=='video'){
          resObj = {
          title: demoResource.value.title,
        type: demoResource.value.type,
        url: demoResource.value.url,
        modifiedAt: serverTimestamp(),
        lastModifiedBy: user.value.email
        }
        }
        
        const res = await updateDoc(docRef, resObj).then(
        content.value = {}
      )}


    

    return { modalTitle, translated, document, tags, props, content, languages, addContent, updateContent, user, userInfo, getImgUrl, files, handleDelete, classrooms, updateContentObj, processForm, videos, resources, accordionItems, demoResource, addResource, forms, updateResourceObj, updateResource, processResForm, enableDuetAI, timeDifferenceInMinutes, updateVisibility, sharedWith, deleteEmail, deleteDomain, sharedWithDomain, handleDemoDelete, actions, email, updateGroups, deleteGroup, groups}
  },
  components: {
    //  "demo-content-cards": DemoContentCards,
    // "accordion": Accordion,
        'editor': Editor,
        CountdownTimer

  },
data() {
    return {
        contentId: null,
        contentType: null
    }},
  mounted() {
  var addLanguageContent = document.getElementById('addLanguageContent')
  addLanguageContent.addEventListener('show.bs.modal', function (event) {
  // Button that triggered the modal
  var button = event.relatedTarget
  // Extract info from data-bs-* attributes
  var contentId = button.getAttribute('data-bs-whatever')
  var contentType = button.getAttribute('data-bs-type')
  // Update the modal's content.
  var modalTitle = addLanguageContent.querySelector('.modal-title')

  modalTitle.setAttribute('data-id',contentId)
  modalTitle.setAttribute('data-type',contentType)
})
  window.scrollTo(0, 0)

  }
}
</script>

<style>
  #badge {
  background-color: #F4B400;
}

</style>
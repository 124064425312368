<template>
<section class="mt-3">
    <div class="container-lg">
        <div class="row">
        <div>
            <router-link :to="{ name: 'DemoDetails', params: { id: props.id}}" class="btn btn-outline-primary border-0 mb-5"><i class="bi bi-arrow-left"></i> Back to demo details</router-link>
            <h1 class="display-4">{{document.title[userInfo.document.language] || document.title['en']}}</h1>
        </div>
        <p class="lead">{{ translated.intro}}</p>
    </div>
    <div class="row">
        <form @submit.prevent="handleSubmit">
            <div class="col-lg-6">
              <label for="sessionName" class="form-label">{{ translated.sessionName}}:</label>
              <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Your unique event name should be quite short. It will become the delegates usernames. E.g. if you enter 'NYC Training', then emails will be nyctraining1@demogfe.com etc.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
          <div class="input-group mb-3">
            <span class="input-group-text">
                <i class="bi bi-type"></i>
              </span>
              <input type="text" id="sessionName" class="form-control"
    :class="{
        'is-invalid': stats.sessionNames.includes(demo.sessionName.toLowerCase().replace(/\s+/g, '')),
        'is-invalid': !isValidSessionName(demo.sessionName)
    }"
    v-model="demo.sessionName" required />
              <div class="invalid-feedback">
                {{translated.uniqueName}}
              </div>
          </div>
            <label for="type" class="form-label">{{translated.type}}:</label>
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="bi bi-menu-button-wide-fill text-secondary"></i>
              </span>
              <select class="form-select" id="type" v-model="demo.type" required>
                <option value="online" selected>{{translated.typeForm.online}}</option>
                <option value="inperson">{{translated.typeForm.inPerson}}</option>
              </select>
            </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 col-sm-6">
            <label for="startDate">{{translated.startDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Enter the start of your event. Google Workspace upgrade licenses will be assigned to users shortly before your event is due to start.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="startDate" class="form-control" type="datetime-local" :disabled="document.title['en'] == 'Gemini for Workspace'" v-model="demo.startDate" step="60" required/>
            <span id="startDateSelected"></span>
        </div>
        <div class="col-lg-3 col-sm-6">
            <label for="endDate">{{translated.endDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Enter the end of your event. Google Workspace upgrade licenses will be removed from users after your event has ended.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="endDate" class="form-control" type="datetime-local" :disabled="document.title['en'] == 'Gemini for Workspace'" :min="demo.startDate" v-model="demo.endDate" :max="getMaxEndDate" step="60" required/>
            <span id="endDateSelected"></span>
        </div>
        </div>
            <div class="row">
                
            </div>
            <div class="col-lg-6">
                <label for="language" class="form-label">{{translated.language}}</label>
                <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Not all events are in the localized languages. Assets default to English if they are not found in the selected language.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
          <div class="input-group mb-4">
            <span class="input-group-text">
              <i class="bi bi-translate text-secondary"></i>
            </span>
              <select class="form-select" aria-label="Language" id="language" v-model="demo.language" required>
                <option v-for="lang in demoLanguages" :value="lang.code" :key="lang.code">{{ lang.locale }} <span v-if="lang.locale != lang.localeInLanguage">- {{ lang.localeInLanguage }}</span></option>
              </select>
          </div>

          <label for="timezone" class="form-label">{{translated.timezone}}</label>
          <div class="input-group mb-4">
            <span class="input-group-text">
              <i class="bi bi-globe text-secondary"></i>
            </span>
              <select class="form-select" aria-label="Timezone" id="timezone" v-model="demo.timezone" required>
                <option v-for="timezone in timezones" :value="timezone" :key="timezone">{{ `${timezone} (GMT${moment.tz(timezone).format('Z')})` }}</option>
              </select>
          </div>

          

          <div class="form-check form-switch ms-2" >
            <input class="form-check-input" type="checkbox" id="users" v-model="demo.users">
            <label class="form-check-label ms-2" for="users">{{translated.users}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Enable this option to create student users for delegates to login with during your event. User accounts are automatically enrolled to your Google Classroom and can optionally access Google Workspace upgrade features.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.users">
              <!-- <label for="username" class="form-label">Choose a unique delegate username:</label>
          <div class="input-group mb-3">
            <span class="input-group-text">
                <i class="bi bi-person"></i>
              </span>
              <input type="text" id="username" class="form-control" v-model="demo.username" required />
          </div> -->
          <label for="userNumber" class="form-label">{{translated.userNumber}}:</label>
          <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="How many delegate accounts you will need. Enter a number between 1 and 100.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
          <div class="input-group mb-3">
            <span class="input-group-text">
                <i class="bi bi-people"></i>
              </span>
              <input type="number" id="userNumber" 
         :max="maxUserNumber" 
         min="1" class="form-control" 
         v-model="demo.userNumber" required />

          </div>
          <label for="userLicense" class="form-label">{{translated.userLicense}}:</label>
          <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="The Plus license is recommended but you can select the license you would like to apply for your demo.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
          <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="bi bi-menu-button-wide-fill text-secondary"></i>
              </span>
              <select class="form-select" id="userLicense" v-model="demo.userLicense" required>
                <option value="1010310008">Google Workspace for Education Plus</option>
                <option value="1010310009">Google Workspace for Education Plus (Staff)</option>
                <option value="1010370001">Google Workspace for Education: Teaching and Learning Upgrade</option>
                <option value="1010310002">Google Workspace for Education Plus - Legacy</option>
                <option value="none" selected>Google Workspace for Education Fundamentals</option>
              </select>
            </div>
            </div>

            <!-- Gemini -->
            <div class="form-check form-switch ms-2" v-if="document.tags.includes('Gemini')">
            <input class="form-check-input" type="checkbox" id="duetAI" v-model="demo.actions.duetAI">
            <label class="form-check-label ms-2" for="duetAI">Enable Gemini for Workspace</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select this option to enable Gemini for Workspace for your student users.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.actions.duetAI && document.tags.includes('Gemini')">
              <p>Your student delegate accounts will be assigned a Gemini for Workspace license.</p>
              <p>Note: Gemini for Workspace licenses can only be assigned to a maximum of 30 students accounts for up to 8 hours.</p>
            </div>
            <div class="row mb-3" v-if="demo.actions.duetAI && document.tags.includes('Gemini')">
                <div class="col-lg-6 col-sm-6">
            <label for="duetAIstartDate">{{translated.startDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select start time for Gemini for Workspace licenses to be assigned.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="duetAIstartDate" class="form-control" type="datetime-local" :min="demo.startDate" :max="demo.endDate" v-model="demo.duetAIstartDate" step="60" required/>
            <span id="startDateSelected"></span>
        </div>
        <div class="col-lg-6 col-sm-6">
            <label for="duetAIendDate">{{translated.endDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="End time for Gemini for Workspace license.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="duetAIendDate" class="form-control" type="datetime-local" disabled :min="demo.duetAIstartDate" v-model="demo.duetAIendDate" step="60" required/>
            <span id="endDateSelected"></span>
        </div>
        </div>





            <div class="mt-4" v-for="action in files" :key="action">
              <div class="form-check form-switch ms-2" v-if="action.languageFileName.en == 'OriginalitySubmit'">
            <input class="form-check-input" type="checkbox" id="originality" v-model="demo.actions.originality">
            <label class="form-check-label ms-2" for="originality">{{translated.originality}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select this option to have a demo student automatically submit work to Google Classroom during your event.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.actions.originality">
              <p>{{translated.originalityInfo}}</p>
            </div>

            <div class="form-check form-switch ms-2" v-if="action.languageFileName.en == 'PracticeSetsInsights'">
            <input class="form-check-input" type="checkbox" id="practicesets" v-model="demo.actions.practiceSetsInsights">
            <label class="form-check-label ms-2" for="practicesets">Enable Practice sets insights Google Classroom</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select this option to be added to the Practice sets insights Google Classroom to demo the grading experience.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.actions.practiceSetsInsights">
              <p>You will be added to the Practice Sets [Insights] Google Classroom enabling you to demo the grading experience in Practice sets.</p>
            </div>

            <div class="form-check form-switch ms-2" v-if="action.languageFileName.en == 'ClassVisit'">
            <input class="form-check-input" type="checkbox" id="classvisit" v-model="demo.actions.classVisit">
            <label class="form-check-label ms-2" for="classvisit">Enable Classroom Analytics and visit a class (Education Plus)</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select this option to enable class visits for Google Classroom.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.actions.classVisit && action.languageFileName.en == 'ClassVisit'">
              <p>You and your student accounts will be assigned an admin role enabling you to access the <a href="https://support.google.com/edu/classroom/answer/13651766" target="_blank">visit a class</a> and <a href="https://support.google.com/edu/classroom/answer/14221372" target="_blank">Classroom analytics</a> features.</p>
            </div>


            <div class="form-check form-switch ms-2" v-if="action.languageFileName.en == 'DuetAI'">
            <input class="form-check-input" type="checkbox" id="duetAI" v-model="demo.actions.duetAI">
            <label class="form-check-label ms-2" for="duetAI">Enable Gemini for Workspace</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select this option to enable Gemini for Workspace for your student users.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
            <div class="mt-4" v-if="demo.actions.duetAI && action.languageFileName.en == 'DuetAI'">
              <p>You student delegate accounts will be assigned a Gemini for Workspace license.</p>
              <p>Note: Gemini for Workspace licenses can only be assigned to a maximum of 5 students accounuts for up to 8 hours.</p>
            </div>
            <div class="row mb-3" v-if="demo.actions.duetAI && action.languageFileName.en == 'DuetAI'">
                <div class="col-lg-6 col-sm-6">
            <label for="duetAIstartDate">{{translated.startDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Select start time for Gemini for Workspace licenses to be assigned.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="duetAIstartDate" class="form-control" type="datetime-local" :min="demo.startDate" :max="demo.endDate" v-model="demo.duetAIstartDate" step="60" required/>
            <span id="startDateSelected"></span>
        </div>
        <div class="col-lg-6 col-sm-6">
            <label for="duetAIendDate">{{translated.endDate}}</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="End time for Gemini for Workspace license.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            <input id="duetAIendDate" class="form-control" type="datetime-local" disabled :min="demo.duetAIstartDate" v-model="demo.duetAIendDate" step="60" required/>
            <span id="endDateSelected"></span>
        </div>
        </div>
            
            

            </div>  
            <div class="mb-4 mt-4 text-left">
              <button type="submit" :disabled="!isValidSessionName(demo.sessionName)" class="btn btn-primary">Create demo <i class="bi bi-arrow-right-square"></i></button>
            </div>
            </div>
            
            
        </form>    
    </div>
    </div>
</section>
  
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { watch } from 'vue';
import axios from "axios"
import translate from '@/composables/translate'
//import { listTz, tzRawData, findTzByName } from 'timezone-select-js'
import moment from 'moment'
import 'moment-timezone';
import getCollection from '@/composables/getCollection'
import getSubCollection from '@/composables/getSubCollection'
import { Tooltip } from "bootstrap";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import DemoContentTable from '@/components/DemoContentTable.vue'
import getUser from '@/composables/getUser'
import getDoc from '@/composables/getDoc'
// firebase imports
import { db } from '@/firebase/config'
import { writeBatch, doc, addDoc, deleteDoc, updateDoc, collection, serverTimestamp, Timestamp, arrayUnion } from 'firebase/firestore'
export default {
    props: ['id'],
    setup(props){
        const router = useRouter()
        const { documents: files } = getSubCollection('demos',props.id,'content',['fileType','==','action'],'')
        const { documents: items } = getSubCollection('demos',props.id,'content',['fileType','!=','action'],'')
        const { user,userInfo } = getUser()
        const store = useStore()
        const lang = computed(() => store.state.lang)

        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let continent, region;

        if (timeZone && timeZone.includes('/')) {
            let parts = timeZone.split('/');
            continent = parts[0];
            region = parts[1];
        } else {
            console.error('Could not parse time zone:', timeZone);
            continent = 'Unknown';
            region = 'Unknown';
        }

        const classroomExists = computed(() => items.value.some(item => item.fileType === 'classroom')); 

        console.log('classroom',classroomExists)


        const demo = ref({
        demoId: props.id,
        type: '',
        sessionName: '',
        startDate: '',
        endDate: '',
        language: '',
        users: false,
        username: '',
        latLong: '',
        timezone: timeZone,
        continent: continent,
        region: region,
        userNumber: '',
        userLicense: '1010310008',
        actions: {
          originality: false,
          practiceSetsInsights: false,
          classVisit: false,
          duetAI: false
        },
        duetAIstartDate: '',
        duetAIendDate: '',
        classroom: classroomExists,
        groups: ''
        })

        const isValidSessionName = (sessionName) => {
  if (sessionName === '') return true;  // Empty string is not valid
  if (!/^[a-zA-Z0-9\-_]+$/.test(sessionName)) return false; // Invalid characters

  // Check if the session name is already in use (assuming case-insensitive match)
  const formattedSessionName = sessionName.toLowerCase().replace(/\s+/g, '');
  return !stats.value.sessionNames.includes(formattedSessionName);
};


const maxUserNumber = computed(() => demo.value.actions.duetAI ? 30 : 200);




        // set date for duet ai license assignment
        if (props.id === "FH4Xiu2YTLhZHWz91aGI") {
            const now = moment();
            const fourHoursLater = moment().add(8, 'hours');

            demo.value.startDate = now.format('YYYY-MM-DD HH:mm');
            demo.value.endDate = fourHoursLater.format('YYYY-MM-DD HH:mm');
        }

        // if (props.id === "cx2wX1qx78uyJKfiSSab" ) {
        //     const now = moment();
        //     const fourHoursLater = moment(demo.value.duetAIendDate).add(8, 'hours');

        //     demo.value.duetAIstartDate = now.format('YYYY-MM-DD HH:mm');
        //     demo.value.duetAIendDate = fourHoursLater.format('YYYY-MM-DD HH:mm');
        // }

        watch(() => demo.value.duetAIstartDate, (newStartDate) => {
            if (newStartDate) {
                const startDate = moment(newStartDate);
                const eightHoursLater = startDate.add(8, 'hours');
                demo.value.duetAIendDate = eightHoursLater.format('YYYY-MM-DDTHH:mm');
            }
        });

        let duetAIstartDate = demo.value.duetAIstartDate !== '' ? Timestamp.fromDate(new Date(demo.value.duetAIstartDate)) : '';
        let duetAIendDate = demo.value.duetAIendDate !== '' ? Timestamp.fromDate(new Date(demo.value.duetAIendDate)) : '';


        const geminiDemos = ['FH4Xiu2YTLhZHWz91aGI','ubPuoztmZz2Lb2T1NOXi','sCydFAeG766y1mq2JVHz']

        if (geminiDemos.includes(props.id)) {
          demo.value.actions.duetAI = true
        }

        // set the user license to GSEfE legacy for security investigation tool demo
        if (props.id==='wMZIL6wWIJvo0vnYjUrr') {
          demo.value.userLicense = '1010310002'
        }

        // set the user license to education plus for the security demo
        if (props.id==='bfq6Kjuaii13fC73djyc') {
          demo.value.userLicense = '1010310008'
        }

        // set practice sets checkbox action to on for the practice sets demo
        if (props.id==='ujEMcfQxtUJ18nb1ZUiS') {
          demo.value.actions.practiceSetsInsights = true
        }

        const timezones = moment.tz.names()
        
        // get user location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(displayLocationInfo);
        }
        function displayLocationInfo(position) {
          const lng = position.coords.longitude;
          const lat = position.coords.latitude;
          console.log(`longitude: ${ lng } | latitude: ${ lat }`);
          demo.value.latLong = `${ lat },${ lng }`
        }

        const getMaxEndDate = computed(() => {
  if (demo.value.startDate) {
    let startDate = new Date(demo.value.startDate);
    startDate.setDate(startDate.getDate() + 28);
    return startDate.toISOString().slice(0, 16);
  }
  return null;  // Or return a default max end date if needed.
});

const timeDifferenceInMinutes = (duetAITime) => {
    // Assuming duetAITime is a Firestore Timestamp
    // Convert it to a JavaScript Date object
    const duetAIDate = duetAITime.toDate();

    // Add 4 hours to the duetAITime
    duetAIDate.setHours(duetAIDate.getHours() + 4);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = duetAIDate - now;

    // Convert difference to minutes (Note: 1 minute = 60,000 milliseconds)
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    return diffInMinutes;
  };

    async function enableDuetAI() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/enableDuetAI?email='+user.value.email+'&userDoc='+userId)
            const colRef = collection(db, 'trainings')

      //   await addDoc(colRef, {
      //     demoId: props.id,
      //     demoName: document.value.title['en'],
      //     sessionName: `${user.value.email} Duet AI`,
      //     type: 'online',
      //     startDate: serverTimestamp(),
      //     endDate: serverTimestamp(),
      //     createdAt: serverTimestamp(),
      //     createdBy: user.value.email,
      //     createdByUid: user.value.uid,
      //     createdByOrg: userInfo.value.document.userDomain,
      //     status: ['active','Classroom created'],
      //     userLicense: '1010470001'
      // })
    }

        
        const { document } = getDoc('demos',props.id)
        const { document: userDoc} = getDoc('users',user.value.uid)
        const { document: stats } = getDoc('config','stats')

        const { getLanguage, translated } = translate()
        getLanguage('createDemo',lang)

        const languages = ref([])
        axios.get('/data/locale.json')
        .then((res) => {
        languages.value = res.data.languages
        })

        const demoLanguages = computed(()=> {
            return languages.value.filter(l => document.value.languages.includes(l.code))
        })

        const handleSubmit = async () => {
        const colRef = collection(db, 'trainings')
        console.log(userDoc.value.orgPath)
        const res = await addDoc(colRef, {
          demoId: props.id,
          demoName: document.value.title['en'],
          sessionName: demo.value.sessionName,
          type: demo.value.type,
          startDate: Timestamp.fromDate(new Date(demo.value.startDate)),
          endDate: Timestamp.fromDate(new Date(demo.value.endDate)),
          language: demo.value.language,
          timezone: demo.value.timezone,
          continent: demo.value.continent,
          region: demo.value.region,
          timezoneOffset: moment.tz(demo.value.timezone).utcOffset(),
          eventTimezone: moment.tz(demo.value.timezone).utcOffset(),
          users: demo.value.users,
          userName: demo.value.sessionName.replace(/\s+/g, ''),
          userNumber: demo.value.userNumber,
          userLicense: demo.value.userLicense,
          createdAt: serverTimestamp(),
          createdBy: user.value.email,
          createdByUid: user.value.uid,
          createdByOrg: userInfo.value.document.userDomain,
          latLong: demo.value.latLong,
          orgPath: userDoc.value.orgPath,
          status: ['Starting demo creation.'],
          meetStatus: true,
          actions: {
          originality: demo.value.actions.originality,
          practiceSetsInsights: demo.value.actions.practiceSetsInsights,
          classVisit: demo.value.actions.classVisit,
          duetAI: demo.value.actions.duetAI
          },
          classrooms: '',
          sharedFiles: [],
          duetAIstartDate: demo.value.duetAIstartDate ? Timestamp.fromDate(new Date(demo.value.duetAIstartDate)) : null,
          duetAIendDate: demo.value.duetAIendDate ? Timestamp.fromDate(new Date(demo.value.duetAIendDate)) : null,
          classroom: demo.value.classroom,
          groups: document.value.groups ? document.value.groups : [] 
      })

      if(demo.value.users === true) {
          let domain = 'demogfe.com'
          // if Google Workspace Security demo set domain to admin.demogfe.com
          if(props.id==='bfq6Kjuaii13fC73djyc'){
            domain = 'admin.demogfe.com'
          }
          console.log('creating users')
          const batch = writeBatch(db)
          for (let i = 1; i <= demo.value.userNumber; i++) {
            const ref = doc(db, 'students', `${demo.value.sessionName.replace(/\s+/g, '')}${i}`)
            batch.set(ref, {
              email: `${demo.value.sessionName.replace(/\s+/g, '')}${i}@${domain}`,
              number: i,
              sessionName: demo.value.sessionName,
              ou: demo.value.sessionName.replace(/\s+/g, ''),
              domain: domain,
              createdAt: serverTimestamp(),
              createdBy: user.value.email,
              createdByUid: user.value.uid,
              language: demo.value.language,
              startDate: Timestamp.fromDate(new Date(demo.value.startDate)),
              endDate: Timestamp.fromDate(new Date(demo.value.endDate)),
              timezone: demo.value.timezone,
              timezoneOffset: moment.tz(demo.value.timezone).utcOffset(),
              eventTimezone: moment.tz(demo.value.timezone).utcOffset(),
              orgPath: userDoc.value.orgPath,
              userLicense: demo.value.userLicense,
              licenseStart: Timestamp.fromDate(new Date(demo.value.startDate)),
              licenseEnd: Timestamp.fromDate(new Date(demo.value.endDate)),
              licenseStatus: 'pending',
              status: 'pending',
              duetAI: demo.value.actions.duetAI,
              duetAIstartDate: demo.value.duetAIstartDate ? Timestamp.fromDate(new Date(demo.value.duetAIstartDate)) : null,
              duetAIendDate: demo.value.duetAIendDate ? Timestamp.fromDate(new Date(demo.value.duetAIendDate)) : null
              //moment(demo.value.endDate).add(findTzByName(demo.value.timezone).offsetValue,'minutes').format('MM/DD/YYYY hh:mm:ss')
            })
          }
          await batch.commit();
        }

        // assign duet ai license for duet demo
        if(props.id === "FH4Xiu2YTLhZHWz91aGI") {
          enableDuetAI()
        }
      const updateStats = async () => {
        const docRef = doc(db, 'config', 'stats')
        await updateDoc(docRef, {
        sessionNames: arrayUnion(demo.value.sessionName.toLowerCase().replace(/\s+/g, ''))
        })}
        await updateStats()
      
      router.push({ name: 'SessionDetails', params: {id: res.id} })
    }

        return { props, user, userInfo, document, demo, demoLanguages, handleSubmit, router, files, timezones, moment, stats, translated, getMaxEndDate, isValidSessionName, maxUserNumber, classroomExists }
    },
  mounted() {
    const tooltips = document.querySelectorAll('.tt')
    tooltips.forEach(t => {
      new Tooltip(t)
    })
  },
  updated(){
    const tooltips = document.querySelectorAll('.tt')
    tooltips.forEach(t => {
      new Tooltip(t)
    })
  }

}
</script>
    
<style>
.form-check-input {
  transform: scale(1.4);
}
::-webkit-datetime-edit-second-field {
    background: white;
    color: transparent;
    margin-left: -3px;
    position: absolute;
    width: 1px;
  }
</style>
<template>
<section class="mt-3">
  <div class="container-lg">
    <button class="btn btn-outline-primary border-0 mb-5" @click="router.go(-1)"><i class="bi bi-arrow-left"></i> Back to demo templates</button>
    <form @submit.prevent="handleSubmit">
    <div class="row">
      <h1>Build custom demo</h1>
    
      <div class="col-md-7">
      <label for="title" class="form-label">Title (English):</label>
      <div class="input-group mb-4">
              <input type="text" id="title" class="form-control" v-model="demo.title.en" required />
      </div>
      <label for="description" class="form-label">Description (English):</label>
      <div class="input-group mb-4">
              <textarea name="description" id="description" class="form-control"  rows="3" v-model="demo.description.en"></textarea>
      </div>
      <!-- <label for="visibility" class="form-label">Visibility:</label>
      <div class="mb-4 input-group">
              <select class="form-select" id="visibility" v-model="demo.visibility" required>
                <option value="private">Private</option>
                <option value="public" v-if="userInfo.document.admin">Public</option>
              </select>
            </div> -->
                  <label for="demoTime" class="form-label">Length of demo (minutes):</label>
            <div class="input-group mb-3">
              <input type="demoTime" id="length" min="1" max="1000" class="form-control" v-model="demo.demoTime" required />
          </div>
      </div>
      <div class="col-md-5">
        <div v-for="form in forms" :key="form.title" class="mt-2">
                <h5>{{ form.title }}</h5>
                <div v-for="field in form.fields" :key="field.value">
                    <div :id="form.title.replace(/\s+/g, '-').toLowerCase()">
                  <div class="form-check">
                      <check-box :fieldId="field.name" v-model:tags="demo.tags" :value="field.value" />
                  </div>
                  </div>
                </div>
                
            
        </div>
        <div class="mt-2">
          <h5>Apps in demo</h5>
               <div v-for="app in apps" :key="app">
                    <div :id="app">
                  <div class="form-check">
                      <check-box :fieldId="app" v-model:tags="demo.apps" :value="app" />
                  </div>
                  </div>
                </div> 
        </div>
             
      </div>
      <div class="mb-4 text-left">
              <button type="submit" class="btn btn-secondary"><i class="bi bi-save"></i> Save</button>
      </div>
    
    </div>
    </form>
  </div>
</section>
</template>

<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
//import { computed } from '@vue/reactivity'
import getUser from '@/composables/getUser'
import getTags from '@/composables/getTags'
import Checkbox from '@/components/CheckBox.vue'
import getCollection from '@/composables/getCollection'
import translate from '@/composables/translate'
import { useStore } from 'vuex'
// firebase imports
import { db } from '@/firebase/config'
import { addDoc, collection, serverTimestamp, doc, getDoc, updateDoc } from 'firebase/firestore'
import Editor from '@tinymce/tinymce-vue'
export default {
  props: ['id'],
  setup(props) {
    // demo form refs
    const router = useRouter()
    const { user, userInfo } = getUser()
    const demoId = ref(props.id)
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { getLanguage, translated, forms } = translate()
    getLanguage('createDemoContent',lang)
    const demo = ref({
      title: {},
      description: {},
      tags: [],
      content: [],
      post: '',
      visibility: 'private',
      demoTime: '',
      apps: []
    })
    const error = ref(null)
    const load = async () => {
        const docRef = doc(db, 'demos', props.id);
    const docSnap = await getDoc(docRef);
    console.log('props',props)

    if (docSnap.exists()) {
        demo.value = { ...docSnap.data(), id: props.id }
        error.value = null
        console.log("Demo Document data:", props.id);
    } else {
        // doc.data() will be undefined in this case
        error.value = 'that document does not exist'
        //console.log("No such document!");
    }
    }
    load()
    //const { documents: content } = getCollection('content')
    const { documents: fields } = getCollection('forms',
    ['form','==', 'filter']
    )
    const { documents: languages } = getCollection('localeCodes','','code')
        
    //const { documents: tagsArray } = getTags('forms')
    const { documents: tags } = getTags('forms')


    const groups = ['Workspace Edition','Level']

    const apps = ['admin','classroom','drive','docs','forms','gemini','gmail','jamboard','meet','sheets','sites','slides']

    // const files = computed(() => {
    //   return content.value.filter(c => demo.value.content.includes(c.primaryKey))
    // })

    const handleSubmit = async () => {
        
        if(props.id){
          const docRef = doc(db, 'demos', props.id)
        const res = await updateDoc(docRef, {
        // title: {['en']:demo.value.title},
        // description: {['en']:demo.value.description},
        title: demo.value.title,
        description: demo.value.description,
        post: demo.value.post,
        tags: demo.value.tags,
        lastModified: serverTimestamp(),
        lastModifiedBy: user.value.email,
        apps: demo.value.apps,
        demoTime: demo.value.demoTime,
        visibility: demo.value.visibility
        }).then(
          router.push({ name: 'DemoDetails', params: {id: props.id} })
        )
        }
    
        else {
          const colRef = collection(db, 'demos')
      const res = await addDoc(colRef, {
        title: demo.value.title,
        description: demo.value.description,
        post: demo.value.post,
        tags: demo.value.tags,
        createdAt: serverTimestamp(),
        createdByOrg: userInfo.value.document.userDomain,
        lastModified: serverTimestamp(),
        createdBy: user.value.email,
        apps: demo.value.apps,
        demoTime: demo.value.demoTime,
        visibility: demo.value.visibility,
        languages: ['en'],
        count: 0
      })
      router.push({ name: 'DemoDetails', params: {id: res.id} })
        }
      
    }
        
      

    return { demo, handleSubmit, getTags, groups, fields, tags, languages, demoId, router, forms, translated, apps, user, userInfo}
  },
components: {
    "check-box": Checkbox,
    // 'editor': Editor
  }
}
</script>

<style>

</style>